.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



/*

For now when only copying of code is possible, comment this out

svg text.hidden {display: none;}
svg rect.hidden {display: none;}
svg polygon.hidden {display: none;}

svg g:hover text.hidden {display: block;}
svg g:hover rect.hidden {display: block; }
svg g:hover polygon.hidden {display: block; }
*/

.editorSidebar{
  text-align: left;
  overflow-y: auto;
  font-size: 0.8em;
  background-color: #1a1a1d;
  color: #fff;
  border-left: 1px solid #2e2e33;
}

 .editorSidebar h5{
  margin-bottom: 0.3em;
  margin-top: 0.8em;
  color: #d8d8d8;
}

.editorSidebar h4{
  margin-bottom: 0.5em;
  margin-top: 1.00em;
  color: #d8d8d8;
}

.editorSidebar h3{
  color: #71ffd4;
  margin-bottom: 10px;
  margin-top: 25px;
}


.editorLeftSidebar{
  background-color: #1c1d20;
  color: #fff;
  border-right: 1px solid #2e2e33;
}


.editorLeftSidebar button{
  min-height: 60px;
  border: none;
  outline: none;
  box-shadow: none;
  background-color: #1c1d20;
  color: #fff;


}

.editorLeftSidebar button:hover{
  background-color: #2d4e52;
  cursor: pointer;

}


.editorLeftSidebar button.active {
  background-color: #c5c5c5;
  color: #000;
}

.expandable{
  cursor: pointer;
}

.leftGap{
  margin-left: 0.4em;
}

input[type=text], button, select {
  background-color: #0d0e0f;
  margin: 3px;
  border: 1px solid #2b2b38;
  padding: 4px;
  padding-left: 6px;
  padding-right: 6px;
  color: white;
}

button, select{
  background-color: #3c3c42;
}

button:hover, select:hover{
  background-color: #2d524f;

}



 input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  width: 8rem;
}

.extraObjectEditor  input[type="range"]
{
  margin-left: 0.5em;
  margin-right: 0.5em;
}

 .extraObjectEditor button
{
  margin-left: 0.5em;
}


.scrollDownToVideo{
color: #71ffd4;
font-size: 2em;
padding-top: 2em;
}


                       /***** Track Styles *****/
/***** Chrome, Safari, Opera, and Edge Chromium *****/
input[type="range"]::-webkit-slider-runnable-track {
  background: #494d50;
  height: 0.75rem;
}

/******** Firefox ********/
input[type="range"]::-moz-range-track {
  background: #494d50;
  height: 0.75rem;
}

 input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: -3px; /* Centers thumb on the track */
  background-color: #71ffd4;
  height: 1.20rem;
  width: 0.5rem;    
}

/***** Thumb Styles *****/
/***** Firefox *****/
 input[type="range"]::-moz-range-thumb {
  border: none; /*Removes extra border that FF applies*/
  border-radius: 0; /*Removes default border-radius that FF applies*/
  background-color: #71ffd4;
  height: 1.25rem;
  width: 0.5rem;
}
